import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";

import careerImg from "./../../../assets/images/career/career.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const CareerTopImg = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner-ourwork",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap justify-content-center align-items-center px-1"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={tilesClasses}>
            <div
              className="tiles-item-career-top-left reveal-from-left"
              data-reveal-delay="200"
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="h-content-for-w-img-530px">
                  <img
                    src={careerImg}
                    alt="Get in touch"
                    className="w-img-530px img-has-shadow"
                  />
                </div>
              </div>
            </div>
            <div
              className="tiles-item-career-top-right reveal-from-right"
              data-reveal-delay="200"
            >
              <h2 className="justify-title-contactus text-has-shadow mt-0 mb-16">
                <span className="text-color-primary text-nowrap">Career</span>
              </h2>

              <p className="text-sm justify-title-contactus m-0">Work better</p>
              <p className="text-sm justify-title-contactus m-0">
                Grow together
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

CareerTopImg.propTypes = propTypes;
CareerTopImg.defaultProps = defaultProps;

export default CareerTopImg;

import React, { useRef } from "react";

// import sections
import PositionDetail from "../../../components/sections/partials/PositionDetail";

const JrFullStackDeveloper = () => {
  const childRef = useRef();
  const positionName = "Jr. Full-Stack Developer";
  const contentMessage = (
    <p className="text-xs my-0">
      Trade Square is building a team to work on various projects including its
      own logistic platform (Route Optimization). We are looking for a new
      talent to join our team and grow together.
    </p>
  );

  const jobDescriptions = {
    title: "Job Descriptions",
    content: (
      <div className="text-xs my-0">
        <p className="mb-3">
          Aiming to launch our route optimization SaaS product to the logistic
          market for corporate clients this year, you will be part of the alpha
          team to design and develop a highly scalable and adaptable product
          from our early version and also develop a tailored version to meet
          specific business and customer requirements with cutting edge
          technologies to be the best platform in logistic market.
        </p>
        <p className="mb-0">
          We also provide consulting services for architectural design and
          implementation which will help you work on something new from
          time-to-time to keep up with other markets and technology with
          opportunities to lead the project from design to delivery.
        </p>
      </div>
    ),
  };

  const responsibilities = {
    title: "Responsibilities",
    content: (
      <p className="text-xs my-0">
        <li className="m-0">
          Develop and maintain our SaaS Product both base version and client’s
          customized version.
        </li>
        <li className="m-0">
          Improve site performance through monitoring, tuning, best practices,
          and root cause analysis.
        </li>
        <li className="m-0">
          Be an advocate for agile engineering and test-driven development.
        </li>
        <li className="m-0">
          Product development Best Practices including architecture design,
          scalability, and other best practices as applicable.
        </li>
        <li className="m-0">
          Be able to tackle and solve any technical problems/issues reported by
          customers or team.
        </li>
      </p>
    ),
  };

  const requirements = {
    title: "Requirements",
    content: (
      <p className="text-xs my-0">
        <li className="m-0">
          Bachelors or greater degree in Computer Science or equivalent.
        </li>
        <li className="m-0">
          Fluent or at least some experience in React and Python is preferred or
          any other front-end and back-end stack.
        </li>
        <li className="m-0">Familiarity with AWS Services.</li>
        <li className="m-0">Knowledge of database design.</li>
        <li className="m-0">
          An understanding of REST APIs and event-driven architectures.
        </li>
        <li className="m-0">
          Understanding of basic cloud infrastructure and services.
        </li>
        <li className="m-0">
          Self-motivated, good interpersonal skills, good team spirit, and
          responsible attitude.
        </li>
        <li className="m-0">
          Experience with Ruby on Rails will be an advantage (Ruby, Rails,
          jQuery, ElasticSearch, Capistrano).
        </li>
      </p>
    ),
  };

  const benefits = {
    title: "Benefits",
    content: (
      <p className="text-xs my-0">
        <li className="m-0">10 days of annual leave.</li>
        <li className="m-0">
          AWS or other cloud provider Training and certification budget.
        </li>
        <li className="m-0">Thailand Social Security Fund (ประกันสังคม).</li>
        <li className="m-0">At least 14 days holiday on a calendar year.</li>
        <li className="m-0">
          Work from home on a daily basis or as requested occasionally if
          required.
        </li>
        <li className="m-0">
          Flexible working hours but at least 8 hours/day.
        </li>
        <li className="m-0">
          Annual raise and bonus based on personal performance and company
          performance.
        </li>
      </p>
    ),
  };

  const applyNow = {
    title: "Apply Now",
    content: (
      <p className="text-xs my-0">
        <li className="m-0">
            <b>Email:</b> sasina@tradesquareltd.com
          </li>
          <li className="m-0">
            <b>Tel:</b> 0613910641
          </li>
      </p>
    ),
  };

  const previousPageText = "Careers";

  /**
   * {`/our-works/content/#projectId`}
   * #projectId must equal to 'id' in 'projectDetailLink' from ProjectContent
   */
  const previousPageLink = "/careers";

  return (
    <PositionDetail
      childRef={childRef}
      positionName={positionName}
      contentMessage={contentMessage}
      jobDescriptionsTitle={jobDescriptions.title}
      jobDescriptionsContent={jobDescriptions.content}
      responsibilitiesTitle={responsibilities.title}
      responsibilitiesContent={responsibilities.content}
      requirementsTitle={requirements.title}
      requirementsContent={requirements.content}
      benefitsTitle={benefits.title}
      benefitsContent={benefits.content}
      applyNowTitle={applyNow.title}
      applyNowContent={applyNow.content}
      previousPageText={previousPageText}
      previousPageLink={previousPageLink}
    />
  );
};

export default JrFullStackDeveloper;

import React, { useRef } from "react";

// import sections
import PositionDetail from "../../../components/sections/partials/PositionDetail";

const FrontEndDeveloper = () => {
  const childRef = useRef();
  const positionName = "Front-end Developer (Mid Level - Senior)";
  const contentMessage = (
    <div className="text-xs my-0">
      <p className="mb-3">
        Trade Square is building a team to work on various projects including
        its own logistic platform (Route Optimization). We are looking for new
        talent to join our team and grow together.
      </p>
      <p className="mb-3">
        You will be working on multiple projects to design, develop and maintain
        both clients applications and on our own start up platform for
        logistics. Our clients range from small to corporate in various
        industries, providing a lot of opportunities to set foot on different
        types of projects with, potentially, different technologies.
      </p>
      <p className="mb-3">
        You will also have the opportunity to pursue different online courses
        and certification. AWS certification for different roles is encouraging
        as we participated in the AWS partner program but other certifications
        are also possible.
      </p>
      <p className="mb-3">
        As we are working on our product milestone to deliver a full function
        platform for Route Optimization to our client, you, as part of the team,
        will be working on different portions of the platform as well as to
        design the overall architecture of the platform to be highly scalable
        and robust.
      </p>
      <p className="mb-0">
        Through working with our team, your knowledge, skill and initiative will
        help us in providing the best solution to our customers. The technology
        stack we used is primarily React for Front-end and Python/Node.JS as
        Back-end and most of our project will be utilizing and deploying on AWS
        Cloud.
      </p>
    </div>
  );

  const jobDescriptions = {
    title: "Job Descriptions",
    content: (
      <p className="text-xs my-0">
        <li className="m-0">
          Develop high quality, robust and scalable software (Own startup
          product and client solution).
        </li>
        <li className="m-0">
          Involve or be able to lead in Application and architecture design.
        </li>
        <li className="m-0">
          Define and develop technical specifications for each project.
        </li>
        <li className="m-0">
          Solving issues and problems from support team or Customer.
        </li>
        <li className="m-0">
          Determining the structure and design of web pages.
        </li>
        <li className="m-0">
          Ensuring user experience determines design choices.
        </li>
        <li className="m-0">
          Developing features to enhance the user experience.
        </li>
        <li className="m-0">Build reusable, scalable and maintainable code.</li>
        <li className="m-0">
          Analyze, design, and suggest solutions (what need to be improved).
        </li>
        <li className="m-0">Automated front-end testing.</li>
        <li className="m-0">Document important system mechanics.</li>
        <li className="m-0">
          Ensuring web design is optimized for smartphones.
        </li>
        <li className="m-0">
          Optimizing web pages for maximum speed and scalability.
        </li>
      </p>
    ),
  };

  const responsibilities = {
    title: null,
    content: null,
  };

  const requirements = {
    title: "Requirements",
    content: (
      <p className="text-xs my-0">
        <li className="m-0">
          Bachelors or greater degree in Computer Science or equivalent.
        </li>
        <li className="m-0">
          More than 3 years’ experience preferred as Developer, working React
          and Node.js/Python.
        </li>
        <li className="m-0">
          Understanding of Front-end technology both server-side rendering and
          client-side rendering.
        </li>
        <li className="m-0">Understanding of key design principles.</li>
        <li className="m-0">
          Proficiency in HTML, CSS, JavaScript, and jQuery server-side CSS.
        </li>
        <li className="m-0">
          Experience with UX/UI design tool such as Adobe XD or Figma.
        </li>
        <li className="m-0">Experience with responsive and adaptive design.</li>
        <li className="m-0">
          Knowledge and experience with Ruby on Rails is highly preferred (ruby,
          rails, jQuery, ElasticSearch, Capistrano) is a plus.
        </li>
        <li className="m-0">Knowledge of unit testing.</li>
        <li className="m-0">Good problem-solving skills.</li>
        <li className="m-0">Excellent verbal communication skills.</li>
        <li className="m-0">Able to communicate in English.</li>
      </p>
    ),
  };

  const benefits = {
    title: "Benefits",
    content: (
      <p className="text-xs my-0">
        <li className="m-0">10 days of annual leave.</li>
        <li className="m-0">
          AWS or other cloud provider Training and certification budget.
        </li>
        <li className="m-0">Thailand Social Security Fund (ประกันสังคม).</li>
        <li className="m-0">At least 14 days holiday on a calendar year.</li>
        <li className="m-0">
          Work from home on a daily basis or as requested occasionally if
          required.
        </li>
        <li className="m-0">
          Flexible working hours but at least 8 hours/day.
        </li>
        <li className="m-0">
          Annual raise and bonus based on personal performance and company
          performance.
        </li>
      </p>
    ),
  };

  const applyNow = {
    title: "Apply Now",
    content: (
      <p className="text-xs my-0">
         <li className="m-0">
            <b>Email:</b> sasina@tradesquareltd.com
          </li>
          <li className="m-0">
            <b>Tel:</b> 0613910641
          </li>
      </p>
    ),
  };

  const previousPageText = "Careers";

  /**
   * {`/our-works/content/#projectId`}
   * #projectId must equal to 'id' in 'projectDetailLink' from ProjectContent
   */
  const previousPageLink = "/careers";

  return (
    <PositionDetail
      childRef={childRef}
      positionName={positionName}
      contentMessage={contentMessage}
      jobDescriptionsTitle={jobDescriptions.title}
      jobDescriptionsContent={jobDescriptions.content}
      responsibilitiesTitle={responsibilities.title}
      responsibilitiesContent={responsibilities.content}
      requirementsTitle={requirements.title}
      requirementsContent={requirements.content}
      benefitsTitle={benefits.title}
      benefitsContent={benefits.content}
      applyNowTitle={applyNow.title}
      applyNowContent={applyNow.content}
      previousPageText={previousPageText}
      previousPageLink={previousPageLink}
    />
  );
};

export default FrontEndDeveloper;

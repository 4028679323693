export function scrollToTarget(target, duration) {
  const top = target.getBoundingClientRect().top;
  const startPos = window.pageYOffset;
  const diff = top;

  let startTime = null;
  let requestId;

  const loop = function (currentTime) {
    if (!startTime) {
      startTime = currentTime;
    }

    // Elapsed time in miliseconds
    const time = currentTime - startTime;

    const percent = Math.min(time / duration, 1);
    window.scrollTo(0, startPos + diff * percent);

    if (time < duration) {
      // Continue moving
      requestId = window.requestAnimationFrame(loop);
    } else {
      window.cancelAnimationFrame(requestId);
    }
  };
  requestId = window.requestAnimationFrame(loop);
}

import React, { useRef } from "react";

// import sections
import PositionDetail from "../../../components/sections/partials/PositionDetail";

const BackEndDeveloper = () => {
  const childRef = useRef();
  const positionName = "Back-end Developer (Mid Level - Senior)";
  const contentMessage = (
    <div className="text-xs my-0">
      <p className="mb-3">
        Trade Square is building a team to work on various projects including
        its own logistic platform (Route Optimization). We are looking for new
        talent to join our team and grow together.
      </p>
      <p className="mb-3">
        You will be working on multiple projects to design, develop and maintain
        both clients applications and on our own start up platform for
        logistics. Our clients range from small to corporate in various
        industries, providing a lot of opportunities to set foot on different
        types of projects with, potentially, different technologies.
      </p>
      <p className="mb-3">
        You will also have the opportunity to pursue different online courses
        and certification. AWS certification for different roles is encouraging
        as we participated in the AWS partner program but other certifications
        are also possible.
      </p>
      <p className="mb-3">
        As we are working on our product milestone to deliver a full function
        platform for Route Optimization to our client, you, as part of the team,
        will be working on different portions of the platform as well as to
        design the overall architecture of the platform to be highly scalable
        and robust.
      </p>
      <p className="mb-0">
        Through working with our team, your knowledge, skill and initiative will
        help us in providing the best solution to our customers. The technology
        stack we used is primarily React for Front-end and Python/Node.JS as
        Back-end and most of our project will be utilizing and deploying on AWS
        Cloud.
      </p>
    </div>
  );

  const jobDescriptions = {
    title: "Job Descriptions",
    content: (
      <p className="text-xs my-0">
        <li className="m-0">
          Develop high quality, robust and scalable software (Own startup
          product and client solution) focusing on API and database layer.
        </li>
        <li className="m-0">
          Involve or be able to lead in back-end platform design, architecture
          design, cloud service usage and integration.
        </li>
        <li className="m-0">
          Define and develop technical specifications for each project.
        </li>
        <li className="m-0">
          Document crucial system mechanics both functional workflow and
          architecture integration workflow.
        </li>
        <li className="m-0">
          Solving issues and problems from support team or Customer.
        </li>
      </p>
    ),
  };

  const responsibilities = {
    title: null,
    content: null,
  };

  const requirements = {
    title: "Requirements",
    content: (
      <p className="text-xs my-0">
        <li className="m-0">
          Bachelors or greater degree in Computer Science or equivalent.
        </li>
        <li className="m-0">
          More than 3 years’ experience preferred as Back-end developer, working
          on Node.js, Python or Go.
        </li>
        <li className="m-0">
          Understanding of Back-end technology both conventional server engine
          and serverless platform.
        </li>
        <li className="m-0">
          Understanding and able to design table schema on SQL Database engine,
          experienced with database ORM concept.
        </li>
        <li className="m-0">
          Serverless API Framework such as Flask, Chalice on Python or
          Serverless API Framework using Go.
        </li>
        <li className="m-0">
          Understanding on various AWS Cloud services for serverless application
          environment and decoupled application architecture e.g. Authentication
          service, Serverless computation, Container service, API gateway, File
          storage service, NoSQL Database service.
        </li>
        <li className="m-0">
          Understanding with Infrastructure-as-a-Code concept.
        </li>
        <li className="m-0">
          Understanding on Container service e.g. Docker, Kubernetes.
        </li>
        <li className="m-0">
          Knowledge and experience with Ruby on Rails, ElasticSearch,
          Capistrano, jQuery will be an added advantage.
        </li>
        <li className="m-0">
          Experienced with NoSQL database engine will be an added advantage.
        </li>
        <li className="m-0">
          Familiar or experienced with Google OR-Tools and Google Map will be an
          added advantage**.
        </li>
        <li className="m-0">Understanding of agile processes is a plus.</li>
        <li className="m-0">Knowledge of unit testing.</li>
        <li className="m-0">Be able to learn and adapt to new tech stack.</li>
        <li className="m-0">Able to communicate in English.</li>
      </p>
    ),
  };

  const benefits = {
    title: "Benefits",
    content: (
      <p className="text-xs my-0">
        <li className="m-0">10 days of annual leave.</li>
        <li className="m-0">
          AWS or other cloud provider Training and certification budget.
        </li>
        <li className="m-0">Thailand Social Security Fund (ประกันสังคม).</li>
        <li className="m-0">At least 14 days holiday on a calendar year.</li>
        <li className="m-0">
          Work from home on a daily basis or as requested occasionally if
          required.
        </li>
        <li className="m-0">
          Flexible working hours but at least 8 hours/day.
        </li>
        <li className="m-0">
          Annual raise and bonus based on personal performance and company
          performance.
        </li>
      </p>
    ),
  };

  const applyNow = {
    title: "Apply Now",
    content: (
      <p className="text-xs my-0">
         <li className="m-0">
            <b>Email:</b> sasina@tradesquareltd.com
          </li>
          <li className="m-0">
            <b>Tel:</b> 0613910641
          </li>
      </p>
    ),
  };

  const previousPageText = "Careers";

  /**
   * {`/our-works/content/#projectId`}
   * #projectId must equal to 'id' in 'projectDetailLink' from ProjectContent
   */
  const previousPageLink = "/careers";

  return (
    <PositionDetail
      childRef={childRef}
      positionName={positionName}
      contentMessage={contentMessage}
      jobDescriptionsTitle={jobDescriptions.title}
      jobDescriptionsContent={jobDescriptions.content}
      responsibilitiesTitle={responsibilities.title}
      responsibilitiesContent={responsibilities.content}
      requirementsTitle={requirements.title}
      requirementsContent={requirements.content}
      benefitsTitle={benefits.title}
      benefitsContent={benefits.content}
      applyNowTitle={applyNow.title}
      applyNowContent={applyNow.content}
      previousPageText={previousPageText}
      previousPageLink={previousPageLink}
    />
  );
};

export default BackEndDeveloper;

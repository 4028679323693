import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";
import SectionHeader from "../partials/SectionHeader";

import what_we_do_01 from "./../../../assets/images/home/what_we_do_01.svg";
import what_we_do_02 from "./../../../assets/images/home/what_we_do_02.svg";
import what_we_do_03 from "./../../../assets/images/home/what_we_do_03.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const WhatWeDo = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner-whatwedo",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap center-content");

  const sectionHeader = {
    title: "What",
    title_highlight: "We Do",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content pb-0" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-top" data-reveal-delay="100">
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-center d-flex-1 mb-32">
                  <img
                    src={what_we_do_01}
                    alt="What we do - Digital Transformation"
                    className="width-auto h-250px"
                  />
                </div>
                <h5 className="mt-0 mb-12">Digital Transformation</h5>
                <div className="testimonial-item-footer mb-0">
                  <p className="text-xs mb-0">
                    Emphasizing on IT integrated Business strategy with Cloud
                    Technology
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-top" data-reveal-delay="200">
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-center d-flex-1 mb-32">
                  <img
                    src={what_we_do_02}
                    alt="What we do - Idea acceleration"
                    className="width-auto h-250px"
                  />
                </div>
                <h5 className="mt-0 mb-12">Idea Acceleration</h5>
                <div className="testimonial-item-footer mb-0">
                  <p className="text-xs mb-0">
                    Turn your ideas and needs into solid requirement and propose
                    solution
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-top" data-reveal-delay="300">
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-center d-flex-1 mb-32">
                  <img
                    src={what_we_do_03}
                    alt="What we do - Business drivers"
                    className="width-auto h-250px"
                  />
                </div>
                <h5 className="mt-0 mb-12">Business Drivers</h5>
                <div className="testimonial-item-footer mb-0">
                  <p className="text-xs mb-0">
                    <li className="m-0">Cost Optimization</li>
                    <li className="m-0">Maximize ROI</li>
                    <li className="m-0">Operational Improvement</li>
                    <li className="m-0">Innovations</li>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

WhatWeDo.propTypes = propTypes;
WhatWeDo.defaultProps = defaultProps;

export default WhatWeDo;

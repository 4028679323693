import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../../utils/SectionProps";
import Image from "../../elements/Image";

import home_img from "./../../../assets/images/home/home.svg";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const HomeTopImg = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section align-text-custom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner-hero row-content",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content custom-padding">
            <h2
              className="mt-0 mb-16 reveal-from-right text-has-shadow"
              data-reveal-delay="100"
            >
              <span>{`Transform and accelerate `}</span>
              <span className="text-color-primary text-nowrap text-has-shadow">
                YOUR IDEAS
              </span>
              <br />
              <span>{` to reality`}</span>
            </h2>
            <h5
              className="mt-0 mb-16 reveal-from-right text-has-shadow"
              data-reveal-delay="400"
            >
              At Trade Square, we deliver your product as it’s ours.
            </h5>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01 px-4 my-3"
            data-reveal-value="20px"
            data-reveal-delay="400"
          >
            {/* <a href="https://www.tradesquareltd.com/"> */}
            <Image
              className="img-has-shadow"
              src={home_img}
              alt="Home"
              width={1200}
              height={1200}
            />
            {/* </a> */}
          </div>
        </div>
      </div>
    </section>
  );
};

HomeTopImg.propTypes = propTypes;
HomeTopImg.defaultProps = defaultProps;

export default HomeTopImg;

import React from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import ReactGA from "react-ga";

// Views
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

// Home
import Home from "./views/home/Home";

// OurStory
import OurStory from "./views/our-story/OurStory";
import OurService from "./views/our-service";

// OurWork
import OurWork from "./views/our-works/OurWork";
// import SalePortalWebApplication from "./views/our-works/projects/SalePortalWebApplication";
// import SmartAgentWebsite from "./views/our-works/projects/SmartAgentWebsite";
// import SmartDashboard from "./views/our-works/projects/SmartDashboard";
// import SuperFiberSelfServicePortal from "./views/our-works/projects/SuperFiberSelfServicePortal ";
// import DataEngineerOnCloud from "./views/our-works/projects/DataEngineerOnCloud";
// import RouteOptimization from "./views/our-works/projects/RouteOptimization";
// import FactoryPlanningAndEconomicAnalysis from "./views/our-works/projects/FactoryPlanningAndEconomicAnalysis";

// Careers
import Careers from "./views/careers/Careers";
import JrFullStackDeveloper from "./views/careers/Position/JrFullStackDeveloper";

// Contact
import Contact from "./views/contact-us/ContactUs";
import FrontEndDeveloper from "./views/careers/Position/FrontEndDeveloper";
import BackEndDeveloper from "./views/careers/Position/BackEndDeveloper";
import PageNotFound from "./views/page-not-found/PageNotFound";
import SolutionArchitect from "./views/careers/Position/SolutionArchitect";

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const App = () => {
  return (
    <Router>
      <Header />
      <main className="site-content">
        <Switch>
          {/* Home */}
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/home" exact>
            <Redirect to="/" />
          </Route>

          {/* OurStory */}
          <Route path="/our-story" exact>
            <OurStory />
          </Route>
          <Route path="/our-story/">
            <Redirect to="/our-story" />
          </Route>

          {/* OurWork */}
          <Route path="/our-works" exact>
            <OurWork />
          </Route>
          {/* <Route path="/our-works/content/:id" exact>
            <OurWork />
          </Route>
          <Route path="/our-works/content">
            <Redirect to="/our-works" />
          </Route>
          <Route path="/our-works/sale-portal-web-application" exact>
            <SalePortalWebApplication />
          </Route>
          <Route path="/our-works/smart-agent-website" exact>
            <SmartAgentWebsite />
          </Route>
          <Route path="/our-works/smart-dashboard" exact>
            <SmartDashboard />
          </Route>
          <Route path="/our-works/super-fiber-self-service-portal" exact>
            <SuperFiberSelfServicePortal />
          </Route>
          <Route path="/our-works/data-engineer-on-cloud" exact>
            <DataEngineerOnCloud />
          </Route>
          <Route path="/our-works/route-optimization" exact>
            <RouteOptimization />
          </Route>
          <Route path="/our-works/factory-planning-and-economic-analysis" exact>
            <FactoryPlanningAndEconomicAnalysis />
          </Route> */}

          <Route path="/our-services">
            <OurService />
          </Route>

          {/* Careers */}
          <Route path="/careers" exact>
            <Careers />
          </Route>
          <Route path="/careers/jr-full-stack-developer" exact>
            <JrFullStackDeveloper />
          </Route>
          <Route path="/careers/front-end-developer" exact>
            <FrontEndDeveloper />
          </Route>
          <Route path="/careers/back-end-developer" exact>
            <BackEndDeveloper />
          </Route>
          <Route path="/careers/solution-architect" exact>
            <SolutionArchitect />
          </Route>
          <Route path="/careers/">
            <Redirect to="/careers" />
          </Route>

          {/* Contact */}
          <Route path="/contact-us" exact>
            <Contact />
          </Route>
          <Route path="/contact-us/">
            <Redirect to="/contact-us" />
          </Route>

          {/* PageNotFound */}
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </main>
      <Footer />
    </Router>
  );
};

export default App;

import React from "react";
import { Link } from "react-scroll";
import { SectionTilesProps } from "../../../utils/SectionProps";
import classNames from "classnames";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const ListOfContent = ({
  jobDescriptions,
  responsibilities,
  requirements,
  benefits,
  applyNow,
  ...props
}) => {
  const outerClasses = classNames("features-tiles section pt-3 pb-4");
  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        {jobDescriptions && (
          <div className="position-detail-link mb-2">
            <Link
              className="hover-position-detail-link"
              to={`JobDescriptions`}
              href=""
            >
              <li>Job Descriptions ⇩</li>
            </Link>
          </div>
        )}
        {responsibilities && (
          <div className="position-detail-link mb-2">
            <Link
              className="hover-position-detail-link"
              to={`Responsibilities`}
              href=""
            >
              <li>Responsibilities ⇩</li>
            </Link>
          </div>
        )}
        {requirements && (
          <div className="position-detail-link mb-2">
            <Link
              className="hover-position-detail-link"
              to={`Requirements`}
              href=""
            >
              <li>Requirements ⇩</li>
            </Link>
          </div>
        )}
        {benefits && (
          <div className="position-detail-link mb-2">
            <Link
              className="hover-position-detail-link"
              to={`Benefits`}
              href=""
            >
              <li>Benefits ⇩</li>
            </Link>
          </div>
        )}
        <div className="position-detail-link mb-2">
          <Link className="hover-position-detail-link" to={`ApplyNow`} href="">
            <li>Apply Now ⇩</li>
          </Link>
        </div>
      </div>
    </section>
  );
};

ListOfContent.propTypes = propTypes;
ListOfContent.defaultProps = defaultProps;

export default ListOfContent;

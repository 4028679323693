import React from "react";
import classNames from "classnames";
import { Link } from "react-scroll";
import { useLocation } from "react-router-dom";

const FooterNav = ({ className, ...props }) => {
  let location = useLocation();
  const page = location.pathname;
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li className="pb-custom">
          <Link
            className="link-highlight"
            to={`${
              page === "/" || page === "/home"
                ? "HomeTopImg"
                : page === "/our-story"
                ? "OurStoryTopImg"
                : page === "/our-works" || page.includes("/our-works/content/")
                ? "OurWorkTopImg"
                : // : page === "/our-works/sale-portal-web-application" ||
                //   page === "/our-works/smart-agent-website" ||
                //   page === "/our-works/smart-dashboard" ||
                //   page === "/our-works/super-fiber-self-service-portal" ||
                //   page === "/our-works/data-engineer-on-cloud" ||
                //   page === "/our-works/route-optimization" ||
                //   page === "/our-works/factory-planning-and-economic-analysis"
                // ? "NavLink"
                page === "/careers"
                ? "CareerTopImg"
                : page === "/careers/jr-full-stack-developer" ||
                  page === "/careers/front-end-developer" ||
                  page === "/careers/back-end-developer"
                ? "NavLink"
                : page === "/contact-us"
                ? "GetInTouch"
                : "PageNotFound"
            }`}
            href="https://www.tradesquareltd.com"
          >
            Back to Top
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;

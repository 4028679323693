import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import ScrollReveal from "../../utils/ScrollReveal";

// import sections
import OurStoryTopImg from "../../components/sections/our-story/OurStoryTopImg";
import WhoWeAre from "../../components/sections/our-story/WhoWeAre";
import HowWeWork from "../../components/sections/our-story/HowWeWork";
import WhatWeValue from "../../components/sections/our-story/WhatWeValue";

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const OurStory = () => {
  const childRef = useRef();
  let location = useLocation();
  const page = location.pathname;

  useEffect(() => {
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
  }, [location, page]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <React.Fragment>
          <div className="bg-top" />
          <OurStoryTopImg id="OurStoryTopImg" />
          <WhoWeAre id="WhoWeAre" topDivider />
          <HowWeWork id="HowWeWork" topDivider />
          <WhatWeValue invertMobile topDivider id="WhatWeValue" />
          <div className="bg-bottom" />
        </React.Fragment>
      )}
    />
  );
};

export default OurStory;

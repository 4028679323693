import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";
import SectionHeader from "../partials/SectionHeader";

import our_customer_01_true from "./../../../assets/images/home/our_customer_01_true.svg";
import our_customer_02_zanroo from "./../../../assets/images/home/our_customer_02_zanroo.svg";
import our_customer_03_aws from "./../../../assets/images/home/our_customer_03_aws.svg";
import our_customer_04_accenture from "./../../../assets/images/home/our_customer_04_accenture.svg";
import our_customer_05_ats from "./../../../assets/images/home/our_customer_05_ats.svg";
import our_customer_06_shop24 from "./../../../assets/images/home/our_customer_06_shop24.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const OurCustomers = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap justify-content-between px-1");

  const sectionHeader = {
    title: "Our",
    title_highlight: "Customers",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content pb-0" />
          <div className={tilesClasses}>
            <div
              className="tiles-customer-logo reveal-from-top"
              data-reveal-delay="100"
            >
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-center d-flex-1 mb-16">
                  <div className="h-content-for-w-img-80px">
                    <img
                      src={our_customer_01_true}
                      alt="True"
                      className="w-img-80px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tiles-customer-logo reveal-from-top"
              data-reveal-delay="150"
            >
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-center d-flex-1 mb-16">
                  <div className="h-content-for-w-img-80px">
                    <img
                      src={our_customer_02_zanroo}
                      alt="Zanroo"
                      className="w-img-80px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tiles-customer-logo reveal-from-top"
              data-reveal-delay="200"
            >
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-center d-flex-1 mb-16">
                  <div className="h-content-for-w-img-80px">
                    <img
                      src={our_customer_03_aws}
                      alt="AWS"
                      className="w-img-80px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tiles-customer-logo reveal-from-top"
              data-reveal-delay="250"
            >
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-center d-flex-1 mb-16">
                  <div className="h-content-for-w-img-80px">
                    <img
                      src={our_customer_04_accenture}
                      alt="Accenture"
                      className="w-img-80px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tiles-customer-logo reveal-from-top"
              data-reveal-delay="300"
            >
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-center d-flex-1 mb-16">
                  <div className="h-content-for-w-img-80px">
                    <img
                      src={our_customer_05_ats}
                      alt="Auto Smart"
                      className="w-img-80px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tiles-customer-logo reveal-from-top"
              data-reveal-delay="350"
            >
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-center d-flex-1 mb-16">
                  <div className="h-content-for-w-img-80px">
                    <img
                      src={our_customer_06_shop24}
                      alt="Shop24"
                      className="w-img-80px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

OurCustomers.propTypes = propTypes;
OurCustomers.defaultProps = defaultProps;

export default OurCustomers;

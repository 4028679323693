import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import ScrollReveal from "../../utils/ScrollReveal";

// import sections
import HomeTopImg from "../../components/sections/home/HomeTopImg";
import WhatWeDo from "../../components/sections/home/WhatWeDo";
import OurServices from "../../components/sections/home/OurServices";
import OurWorks from "../../components/sections/home/OurWorks";
import OurCustomers from "../../components/sections/home/OurCustomers";

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const Home = () => {
  const childRef = useRef();
  let location = useLocation();
  const page = location.pathname;

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
    goToTop();
  }, [location, page]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <React.Fragment>
          {/* {page} */}
          <div className="bg-top" />
          <HomeTopImg id="HomeTopImg" />
          <WhatWeDo id="WhatWeDo" topDivider />
          <OurServices id="OurServices" topDivider />
          <OurWorks id="OurWorks" topDivider />
          <OurCustomers id="OurCustomers" topDivider />
          <div className="bg-bottom" />
          {/* <HowWeWork id="HowWeWork" />
          <WhatWeValue
            invertMobile
            topDivider
            className="illustration-section-02"
            id="WhatWeValue"
          />
          <ContactUs id="ContactUs" /> */}
          {/* <Cta split /> */}
        </React.Fragment>
      )}
    />
  );
};

export default Home;

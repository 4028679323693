import React from "react";
// import { Link } from "react-router-dom";
// import { Link as LinkScroll } from "react-scroll";
import ProjectTag from "./ProjectTag";

const ProjectContent = ({
  projectId,
  projectName,
  projectImg,
  projectLogoImg,
  tagNames,
  upperDetail,
  lowerDetail,
  projectOwner,
  projectDetailLink,
  className,
  ...props
}) => {
  // let location = useLocation();
  // const page = location.pathname;

  return (
    <div>
      <div
        id={projectId ? projectId : projectName}
        className="row-content has-top-divider"
      >
        <div
          className="tiles-item-ourproject-img reveal-from-left"
          data-reveal-delay="200"
        >
          {projectImg && (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="h-content-for-w-img-450px">
                <img
                  src={projectImg}
                  alt={projectName}
                  className="w-img-450px img-has-shadow"
                />
              </div>
            </div>
          )}
        </div>
        <div
          className="tiles-item-ourproject reveal-from-right"
          data-reveal-delay="200"
        >
          {tagNames && (
            <div className="project-tag-row mb-12">
              {tagNames.map((element, index) => (
                <ProjectTag
                  key={index.toString()}
                  id={index.toString()}
                  tagName={element}
                  projectId={projectId}
                />
              ))}
            </div>
          )}
          <h5 className="mt-0 mb-12">{projectName ? projectName : ""}</h5>
          {upperDetail && (
            <div className="project-detail-top mt-0 mb-32">{upperDetail}</div>
          )}

          {lowerDetail && (
            <div className="project-detail-bottom mt-0 mb-12">
              {lowerDetail}
            </div>
          )}
          {projectOwner && (
            <div className="project-detail-bottom mt-0 mb-12">
              - {projectOwner}
            </div>
          )}

          {
            projectLogoImg ? (
              <div className="project-logo">
                <div className="h-content-for-w-project-logo">
                  <img
                    src={projectLogoImg}
                    alt={projectName}
                    className="w-project-logo"
                  />
                </div>
                {/* {projectDetailLink && (
                <div style={{ width: "100%" }}>
                  <div className="detail-link-project-content text-xxs">
                    <Link className="link-highlight" to={projectDetailLink}>
                      {`detail >`}
                    </Link>
                  </div>
                </div>
              )} */}
              </div>
            ) : null
            // projectDetailLink && (
            //   <div className="detail-link text-xxs">
            //     <Link className="link-highlight" to={projectDetailLink}>
            //       {`detail >`}
            //     </Link>
            //   </div>
            // )
          }
        </div>
      </div>
      {/* <div className="icon-up-link pb-1 px-3">
        <LinkScroll
          className="hover-icon-up-link d-flex justify-content-end"
          to={`OurWorkTopImg`}
          href=""
        >
          ⇧
        </LinkScroll>
      </div> */}
    </div>
  );
};

export default ProjectContent;

import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";
import SectionHeader from "../partials/SectionHeader";

import how_we_work_01 from "./../../../assets/images/our-story/how_we_work_01.svg";
import how_we_work_02 from "./../../../assets/images/our-story/how_we_work_02.svg";
import how_we_work_03 from "./../../../assets/images/our-story/how_we_work_03.svg";
import how_we_work_04 from "./../../../assets/images/our-story/how_we_work_04.svg";
import how_we_work_05 from "./../../../assets/images/our-story/how_we_work_05.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const WhoWeAre = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner-howwework",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap center-content");

  const imgOnTheRightSideClasses = classNames("row-content mb-48");
  const imgOnTheLeftSideClassesMdUp = classNames(
    "row-content show-content-md-up mb-48"
  );
  const imgOnTheLeftSideClassesSmDown = classNames(
    "row-content show-content-sm-down mb-48"
  );

  const sectionHeader = {
    title: "How",
    title_highlight: "We Work",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content pb-0" />
          <div className={tilesClasses}>
            {/* Row 1 - Img on the left */}
            <div className={imgOnTheRightSideClasses}>
              <div
                className="tiles-item-howwework-img-left reveal-from-right"
                data-reveal-delay="200"
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="h-content-for-w-img-150px">
                    <img
                      src={how_we_work_01}
                      alt="Understanding"
                      className="w-img-150px"
                    />
                  </div>
                </div>
              </div>
              <div
                className="tiles-item-howwework-text-right reveal-from-right"
                data-reveal-delay="200"
              >
                <h5 className="my-0">Understanding</h5>
                <p className="text-xs my-0">
                  Work with customer to understand goals and needs, make
                  suggestion/ advice where possible.
                </p>
              </div>
            </div>

            {/* Row 2 - Img on the right */}
            <div className={imgOnTheLeftSideClassesMdUp}>
              <div
                className="tiles-item-howwework-text-left reveal-from-left"
                data-reveal-delay="200"
              >
                <h5 className="my-0">Ideas with Design and Concept</h5>
                <p className="text-xs my-0">
                  Come up with different ideas and demonstration to help see the
                  clearer picture before making any decision.
                </p>
              </div>
              <div
                className="tiles-item-howwework-img-right reveal-from-left"
                data-reveal-delay="200"
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="h-content-for-w-img-150px">
                    <img
                      src={how_we_work_02}
                      alt="Ideas with Design and Concept"
                      className="w-img-150px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={imgOnTheLeftSideClassesSmDown}>
              <div
                className="tiles-item-howwework-img-left reveal-from-right"
                data-reveal-delay="200"
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="h-content-for-w-img-150px">
                    <img
                      src={how_we_work_02}
                      alt="Ideas with Design and Concept"
                      className="w-img-150px"
                    />
                  </div>
                </div>
              </div>
              <div
                className="tiles-item-howwework-text-right reveal-from-right"
                data-reveal-delay="200"
              >
                <h5 className="my-0">Ideas with Design and Concept</h5>
                <p className="text-xs my-0">
                  Come up with different ideas and demonstration to help see the
                  clearer picture before making any decision.
                </p>
              </div>
            </div>

            {/* Row 3 - Img on the left */}
            <div className={imgOnTheRightSideClasses}>
              <div
                className="tiles-item-howwework-img-left reveal-from-right"
                data-reveal-delay="200"
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="h-content-for-w-img-150px">
                    <img
                      src={how_we_work_03}
                      alt="Agree and Execute with Plan"
                      className="w-img-150px"
                    />
                  </div>
                </div>
              </div>
              <div
                className="tiles-item-howwework-text-right reveal-from-right"
                data-reveal-delay="200"
              >
                <h5 className="my-0">Agree and Execute with Plan</h5>
                <p className="text-xs my-0">
                  After Solid idea of the product, setup timeline and execution
                  plan, involving customer opinion and review to keep everyone
                  on the same page, making our working process clear and
                  transparent.
                </p>
              </div>
            </div>

            {/* Row 4 - Img on the right */}
            <div className={imgOnTheLeftSideClassesMdUp}>
              <div
                className="tiles-item-howwework-text-left reveal-from-left"
                data-reveal-delay="200"
              >
                <h5 className="my-0">Trial and Adjust</h5>
                <p className="text-xs my-0">
                  Once solution is up and running we set up trial run and UAT to
                  ensure that the product meet customer’s needs and adjust along
                  the way.
                </p>
              </div>
              <div
                className="tiles-item-howwework-img-right reveal-from-left"
                data-reveal-delay="200"
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="h-content-for-w-img-150px">
                    <img
                      src={how_we_work_04}
                      alt="Trial and Adjust"
                      className="w-img-150px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={imgOnTheLeftSideClassesSmDown}>
              <div
                className="tiles-item-howwework-img-left reveal-from-right"
                data-reveal-delay="200"
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="h-content-for-w-img-150px">
                    <img
                      src={how_we_work_04}
                      alt="Trial and Adjust"
                      className="w-img-150px"
                    />
                  </div>
                </div>
              </div>
              <div
                className="tiles-item-howwework-text-right reveal-from-right"
                data-reveal-delay="200"
              >
                <h5 className="my-0">Trial and Adjust</h5>
                <p className="text-xs my-0">
                  Once solution is up and running we set up trial run and UAT to
                  ensure that the product meet customer’s needs and adjust along
                  the way.
                </p>
              </div>
            </div>

            {/* Row 5 - Img on the left */}
            <div className={imgOnTheRightSideClasses}>
              <div
                className="tiles-item-howwework-img-left reveal-from-right"
                data-reveal-delay="200"
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="h-content-for-w-img-150px">
                    <img
                      src={how_we_work_05}
                      alt="Deploy and Monitor"
                      className="w-img-150px"
                    />
                  </div>
                </div>
              </div>
              <div
                className="tiles-item-howwework-text-right reveal-from-right"
                data-reveal-delay="200"
              >
                <h5 className="my-0">Deploy and Monitor</h5>
                <p className="text-xs my-0 w-100-contactus">
                  Launch product and monitor ensure that everything is working
                  smoothly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

WhoWeAre.propTypes = propTypes;
WhoWeAre.defaultProps = defaultProps;

export default WhoWeAre;

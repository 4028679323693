import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import ScrollReveal from "../../utils/ScrollReveal";

// import sections
import CareerTopImg from "../../components/sections/careers/CareerTopImg";
import CareerList from "../../components/sections/careers/CareerList";

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const Careers = () => {
  const childRef = useRef();
  let location = useLocation();
  const page = location.pathname;

  useEffect(() => {
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
  }, [location, page]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <React.Fragment>
          {/* {page} */}
          <div className="bg-top" />
          <CareerTopImg id="CareerTopImg" />
          <CareerList id="CareerList" />
          <div className="bg-bottom" />
        </React.Fragment>
      )}
    />
  );
};

export default Careers;

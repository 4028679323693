import React from "react";
import classNames from "classnames";
import SectionHeader from "../../components/sections/partials/SectionHeader";
import { SectionTilesProps } from "../../utils/SectionProps";

import aws from "./../../assets/images/home/aws.svg";
import awsxts from "./../../assets/images/home/awsxts.svg";

//Image
import aws_service_01 from "./../../assets/images/our-service/aws_service_01.svg";
import image_aws_service_01 from "./../../assets/images/our-service/image_aws_service_01.svg";
import aws_arrow_01 from "./../../assets/images/our-service/aws_arrow_01.svg";
import image_aws_service_02 from "./../../assets/images/our-service/image_aws_service_02.svg";
import { Link } from "react-router-dom/cjs/react-router-dom";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

export default function OurAWS({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
}) {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner-whoweare",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  //   const tilesClasses = classNames("tiles-wrap center-content");
  //   const pBoClassesMdUp = classNames("row-content show-content-md-up mb-48");
  //   const pBoClassesSmDown = classNames("row-content show-content-sm-down mb-48");
  //   const pKwangClasses = classNames("row-content");

  const sectionHeader = {
    title: "Why We",
    title_highlight: "Do",
  };

  const sectionFirstHeader = {
    title: "Our AWS Professional",
    title_highlight: "Service",
  };

  const sectionReview = {
    title: "Review",
    title_highlight: "Process",
  };

  const sectionWell = {
    title: "AWS Well-Architected Framework",
    title_highlight: "Review",
  };

  const dataReviewProcess = [
    {
      number: 1,
      text: "Engage stakeholders, define workload scope, and ready the customer for the AWS Well-Architected Framework Review.",
      title: "Prepare",
    },
    {
      number: 2,
      text: "Assess the workload, document findings, and generate comprehensive reports based on the Well-Architected Framework's pillars.",
      title: "Review",
    },
    {
      number: 3,
      text: "Identify critical areas, collaborate with the customer to review findings, and strategize treatment plans for immediate improvement.",
      title: "Guide",
    },
  ];

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="mb-5"></div>
          <SectionHeader
            classContainer="container-sm reveal-from-left"
            data={sectionFirstHeader}
            className="center-content pb-0 mx-20 container-sm"
          />
          <div id="our-aws-service">
            <div style={{ marginBottom: "70px " }}>
              <div className="aws-logo reveal-from-right">
                <center>
                  <img
                    style={{ height: "100px" }}
                    src={awsxts}
                    alt="AWS Partner Select Tier Services"
                  />
                </center>
              </div>
              <div className="row-content awsxts-base">
                <div
                  className="reveal-from-left awsxts-content"
                  data-reveal-delay="200"
                >
                  <p
                    className="text-left"
                    style={{ textAlign: "left", fontSize: "18px" }}
                  >
                    TradeSquare has proudly attained the coveted AWS Partner
                    Select Tier status. With a relentless commitment to
                    innovation and excellence, TradeSquare stands at the
                    forefront of the technology landscape.
                    <br></br>
                    <br></br>
                    This significant achievement underscores our profound
                    expertise in leveraging Amazon Web Services (AWS) to deliver
                    cutting-edge solutions that empower businesses to thrive in
                    the digital age.
                    <br></br>
                    <br></br>
                    As an AWS Partner Select Tier member, We has demonstrated a
                    remarkable ability to architect, build, migrate, and manage
                    workloads on the AWS platform. Our deep-seated collaboration
                    with AWS reflects a shared vision of transforming industries
                    through cloud-based transformation. We unrivaled
                    proficiency, paired with our dedication to customer success,
                    solidifies our position as a trusted partner capable of
                    unlocking the full potential of cloud computing for small
                    business and enterprises.
                  </p>
                </div>
                <div className="reveal-from-left" data-reveal-delay="200">
                  <div className="project-logo " style={{ padding: "16%" }}>
                    <div className="h-content-for-w-project-logo">
                      <img src={aws} alt="aws" className=" img-has-shadow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SectionHeader
              sizeTitle="fs-3 mt-8 mb-0"
              data={sectionHeader}
              className="center-content pb-0 mt-5 reveal-from-up"
            />
            <div style={{ position: "relative" }}>
              <img
                src={image_aws_service_01}
                style={{
                  position: "absolute",
                  opacity: "0.1",
                  top: "10%",
                  left: "-30%",
                  zIndex: 0,
                }}
                alt=""
              />
              <div className="row-content awsxts-base reveal-from-left">
                <div
                  className="reveal-from-left awsxts-content"
                  data-reveal-delay="200"
                >
                  <SectionHeader className="text-end p-0" data={sectionWell} />
                  <p style={{ textAlign: "right", fontSize: "18px" }}>
                    As an AWS Select Tier Partner specializing in the AWS
                    Well-Architected Framework, Trade Square focuses on
                    delivering client-centered excellence across the six
                    foundational pillars: Operational Excellence, Security,
                    Reliability, Performance Efficiency, Cost Optimization, and
                    Sustainability. Our strategy revolves around targeted client
                    engagement, leveraging these pillars to assist businesses in
                    achieving optimal AWS infrastructures.
                  </p>
                </div>
                <div className="reveal-from-left" data-reveal-delay="200">
                  <div className="project-logo " style={{ padding: "16%" }}>
                    <div
                      className="h-content-for-w-project-logo"
                      style={{ maxWidth: "400px" }}
                    >
                      <img
                        src={image_aws_service_01}
                        alt="aws"
                        className="img-has-shadow"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p
                className="reveal-from-up"
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  margin: "80px 0",
                }}
              >
                We aim to assist businesses in optimizing their AWS
                architectures by providing expertise through educational
                programs, tailored assessments, and strategic partnerships
                within industry networks. Trade Square's dedication to
                demonstrating the effectiveness of our Well-Architected
                solutions is evident through the success stories of satisfied
                customers and our ongoing efforts to enhance and refine our
                services.
              </p>
              <p
                className="reveal-from-up"
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  margin: "80px 0",
                }}
              >
                Our tailored solutions evolve in line with the latest
                advancements in AWS technology, ensuring that clients benefit
                from cutting-edge, sustainable architectures. At Trade Square,
                client satisfaction remains our paramount metric of success,
                positioning us as the preferred partner for businesses aspiring
                to harness the complete potential of the AWS Well-Architected
                Framework.
              </p>
            </div>
            <div
              className="h-content-for-w-project-logo reveal-from-up"
              style={{ maxWidth: "100%", marginBottom: "90px" }}
            >
              <img
                src={aws_service_01}
                alt="aws"
                style={{ width: "100%" }}
                className="img-has-shadow"
              />
            </div>
          </div>
          <SectionHeader
            sizeTitle="mt-6"
            className="reveal-from-up"
            data={sectionReview}
          />
          <div className="section-process-review reveal-from-up">
            <div className="container-sm">
              {dataReviewProcess.map((item, index) => {
                return (
                  <div
                    key={"aws-process-review-" + index}
                    className="box-reviw"
                  >
                    <div className="number">
                      <h1>{item.number}</h1>
                    </div>
                    <div>
                      <img
                        src={aws_arrow_01}
                        style={{ height: "80px" }}
                        alt="alt"
                      />
                    </div>
                    <div className="container-text">
                      <div className="title">
                        <h2>{item.title}</h2>
                      </div>
                      <div className="text">
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "18px",
                            margin: 0,
                          }}
                        >
                          {item.text}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <img
              src={image_aws_service_02}
              style={{
                width: "40%",
                position: "absolute",
                opacity: "1",
                bottom: "-10%",
                right: "-23%",
                zIndex: 0,
              }}
              alt=""
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link to="/contact-us">
                <button
                  style={{
                    padding: "0 20px",
                    border: "none",
                    height: "53px",
                    background: "#A93346",
                    borderRadius: "5px",
                    marginTop: "40px",
                    color: "white",
                  }}
                >
                  Start Well-Architected Review TODAY...
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

OurAWS.propTypes = propTypes;
OurAWS.defaultProps = defaultProps;
